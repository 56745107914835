<template>
  <div>
    <b-row>
      <b-col
        lg="4"
        md="6"
        sm="12"
        xs="12"
      >
        <b-card v-if="user">
          <validation-observer
            ref="simpleRules"
            v-slot="{ invalid }"
          >
            <b-form>
              <b-form-group
                label="Nombre del Usuario"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.name"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Correo electrónico"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="user.email"
                    type="email"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Teléfono"
              >
                <b-form-input
                  v-model="user.phone"
                />
              </b-form-group>
              <b-form-group
                label="Tipo de Usuario"
              >
                <b-form-select
                  v-model="user.type"
                  :options="userTypeOptions"
                />
              </b-form-group>
              <b-form-group
                v-if="user.type === 'ClienteRed'"
                label="Red"
              >
                <b-form-select
                  v-model="selectedNetwork"
                  :options="networkOptions"
                />
              </b-form-group>
              <b-button
                variant="success"
                :disabled="saving || invalid"
                @click="onSaveClick"
              >
                Guardar
                <b-spinner
                  v-if="saving"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BCard, BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import users from '../../../services/users'
import networks from '../../../services/networks'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: '',
        type: 'Cliente',
      },
      saving: false,
      userTypes: [
        'Cliente',
        'Asesor',
        'Cliente Red',
        'Gerente',
      ],
      networks: [],
      selectedNetwork: '',
      required,
      email,
    }
  },
  computed: {
    networkOptions() {
      return this.networks.map(n => ({ value: n['.key'], text: n.name }))
    },
    userTypeOptions() {
      return [
        { value: '', text: '(Seleccione una red de la lista)' },
        { value: 'Cliente', text: 'Cliente' },
        { value: 'Asesor', text: 'Asesor' },
        { value: 'ClienteRed', text: 'Cliente Red' },
        { value: 'Gerencia', text: 'Gerencia' },
      ]
    },
  },
  firebase: {
    networks: networks.bindAll(),
  },
  methods: {
    async onSaveClick() {
      this.saving = true
      if (this.user.type === 'ClienteRed' && this.selectedNetwork !== '') {
        const nw = this.networks.map(n => ({ id: n['.key'], name: n.name })).find(n => n.id === this.selectedNetwork)
        this.user.network = nw
      } else {
        this.user.network = { id: '', name: '' }
      }
      try {
        const result = await users.create(this.user)
        if (result.error) {
          this.showNotification('Error al crear usuario', result.error, 'AlertCircleIcon', 'danger')
        } else {
          this.showNotification('Listo', 'Se creó el usuario exitosamente', 'CheckIcon', 'success')
          this.$router.push({ name: 'admin-users' })
        }
      } catch (err) {
        this.showNotification('Error al crear usuario', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.saving = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
